import "core-js/stable";
import "regenerator-runtime/runtime";

import "./js/config.js";

// Load in vendor scripts (loaded by Webpack directly);
import "bootstrap";

import('./js/form-validation');

// Lazysizes
import 'lazysizes';
import 'lazysizes/plugins/native-loading/ls.native-loading';


// Load in styles
import "./scss/styles.scss";


// Conditional dynamic imports

// Webpack will store those modules in separate JS files and
// load them on demand, keeping payload down.


// Load in custom JS modules required on initial page load

import 'sharer.js';

import('./js/viewport-size');
import('./js/recatpcha');

if (document.querySelector('body.site-layout') != null) {
    import('./js/keyboard-accessibility-check');
    import('./js/scrolling');
}

if (document.querySelector('#main-menu') != null) {
    import('./js/main-navigation');
}

if (document.querySelector('.full-height-top-section .swiper') != null) {
    import('./modules/top-section-swiper/top-section-swiper.js');
}

if (document.getElementById('registration-form') != null) {
    import('./js/register-form');
}

if (document.querySelector('.emergency-message') != null) {
    import('./js/alert-message');
}

// Load in custom JS modules on initial page load and when DOM is updated

const addDataJsParsed = (elements, tag = '') => {
    elements.forEach(x => {
        x.setAttribute(`data-js-parsed--${tag}`,'');
    });
}

const jsModuleCheck = () => {

    let testQuery = null;

    // smart-bootstrap-tabs
    testQuery = document.querySelector('.nav[role="tablist"]:not([data-js-parsed--smart-bootstrap-tabs])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.nav[role="tablist"]:not([data-js-parsed--smart-bootstrap-tabs])');
        addDataJsParsed(thisDomQuery, "smart-bootstrap-tabs");
        import('./js/smart-bootstrap-tabs').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // defer-iframe-content.js
    testQuery = document.querySelector('iframe.defer-iframe-content[data-src]:not([data-js-parsed--defer-iframe-content])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('iframe.defer-iframe-content[data-src]:not([data-js-parsed--defer-iframe-content])');
        addDataJsParsed(thisDomQuery, "defer-iframe-content");
        import('./js/defer-iframe-content').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // height matching
    testQuery = document.querySelector('*[data-hm-group]:not([data-js-parsed--height-match])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('*[data-hm-group]:not([data-js-parsed--height-match])');
        addDataJsParsed(thisDomQuery, "height-match");
        import('./js/height-match').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // hunt-watch
    testQuery = document.querySelector('.hunt-watch:not([data-js-parsed--hunt-watch])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.hunt-watch:not([data-js-parsed--hunt-watch])');
        addDataJsParsed(thisDomQuery, "hunt-watch");
        import('./js/hunt-watch').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // lightboxes (PhotoSwipe v5 - galleries and single images)
    testQuery = document.querySelector('.lightbox-image:not([data-js-parsed--lightbox]), .image-gallery:not([data-js-parsed--lightbox])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.lightbox-image:not([data-js-parsed--lightbox]), .image-gallery:not([data-js-parsed--lightbox])');
        addDataJsParsed(thisDomQuery, "lightbox");
        import('./modules/lightboxes-galleries/lightboxes.js').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // width-watcher
    testQuery = document.querySelector('*[data-width-watcher]:not([data-js-parsed--width-watcher])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('*[data-width-watcher]:not([data-js-parsed--width-watcher])');
        addDataJsParsed(thisDomQuery, "width-watcher");
        import('./js/width-watcher').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // logos-swiper
    testQuery = document.querySelector('.logos-swiper:not([data-js-parsed--logos-swiper])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.logos-swiper:not([data-js-parsed--logos-swiper])');
        addDataJsParsed(thisDomQuery, "logos-swiper");
        import('./modules/logos-swiper/logos-swiper.js').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // article-listing-swiper
    testQuery = document.querySelector('.article-listing-swiper:not([data-js-parsed--article-listing-swiper])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.article-listing-swiper:not([data-js-parsed--article-listing-swiper])');
        addDataJsParsed(thisDomQuery, "article-listing-swiper");
        import('./modules/article-listing-swiper/article-listing-swiper.js').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // print-btn
    testQuery = document.querySelector('.print-btn:not([data-js-parsed--print-btn])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.print-btn:not([data-js-parsed--print-btn])');
        addDataJsParsed(thisDomQuery, "print-btn");
        import('./js/print-button').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

};

//// create a mutation observer to watch for dynamically added HTML

let checkRequested = false, checkTimeout = null;

// run a check on page load
jsModuleCheck();

// Select the node that will be observed for mutations
const targetNode = document.querySelector('body');
if(targetNode != null) {
    // Options for the observer (which mutations to observe)
    const config = { attributes: false, childList: true, subtree: true };
    // Callback function to execute when mutations are observed
    const callback = function(mutationsList) {
        // Use traditional 'for loops' for IE 11
        let checkNeeded = false;
        for(const mutation of mutationsList) {
            if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                checkNeeded = true;
            }
        }
        if(checkNeeded && !checkRequested) {
            checkRequested = true;
            clearTimeout(checkTimeout);
            checkTimeout = setTimeout(() => {
                jsModuleCheck();
                checkRequested = false;
            }, 100);
        }
    };
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);
    setTimeout(() => {
        // do another check now
        jsModuleCheck();
        // Start observing the target node for configured mutations
        observer.observe(targetNode, config);
    }, 3000);
}

